// 文化 - 制度
import { CULTURE } from '../api'
import { METHOD, request } from '@/utils/request'

// 查询制度列表
export async function getInstitutionList(params) {
  return request(`${CULTURE}/institution/getInstitutionList`, METHOD.GET, params)
}

// 查询制度详情
export async function getInstitutionDetail(params) {
  return request(`${CULTURE}/institution/getInstitutionDetail`, METHOD.GET, params)
}

// 添加制度
export async function addInstitution(params) {
  return request(`${CULTURE}/institution/addInstitution`, METHOD.POST, params)
}

// 删除制度
export async function delInstitution(params) {
  return request(`${CULTURE}/institution/delInstitution`, METHOD.POST, params)
}
